import React from 'react';
import { Script } from 'gatsby';

export const wrapPageElement = ({ element }) => (
  <>
    {/* <Script
      id="gtag-call-tracking"
      dangerouslySetInnerHTML={{
        __html: `gtag('config', 'AW-362968454/xQVUCJSnifsDEIbria0B', {
    'phone_conversion_number': '(251) 207-1147'
  });`,
      }}
    /> */}
    {element}
  </>
);
